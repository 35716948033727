.main-display-label-top {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 1.3rem;
}

.main-display-label-middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1.5rem;
  text-align: center;
}

.main-display-label-bottom {
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 1.5rem;
}
