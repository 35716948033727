.live-header {
  position: absolute;
  width: 100%;
  display: flex;
  padding: 10px;
  top: 0px;
  left: 0px;
  z-index: 890;
}

.live-more-body {
  padding: 16px;
}

.shake_bell {
  transform-origin: 50% 0%;
  animation-name: shake;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-delay: 0s;
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(15deg);
  }
  20% {
    transform: rotate(-15deg);
  }
  30% {
    transform: rotate(10deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(2deg);
  }
  60% {
    transform: rotate(-2deg);
  }
  70% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
