.live-notice-wrapper {
  padding: 8px;
  height: 42px;
}

.live-notice-root {
  position: absolute;
  left: 8px;
  right: 73px;
  padding: 4px 5px;
  box-sizing: border-box;
  background-color: transparent;
  border: solid 1px;
  border-color: snow;
  border-radius: 20px;
  height: 34px;
}

.notice-slider {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 24px;
}

.notice-silder-list {
  width: 100%;
  height: 500px;
  position: absolute;
  top: 0px;
  margin: 0;
  padding: 0 10px;
}

.notice-silder-list-animation {
  width: 100%;
  height: 500px;
  position: absolute;
  top: -24px;
  margin: 0;
  padding: 0 10px;
  transition: all 2s;
}

.notice-silder-list div {
  display: block;
}

.notice-silder-list-animation div {
  display: block;
}

.notice-silder-item {
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.live-notice-more {
  padding: 16px;
}
