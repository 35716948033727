.card-container {
  height: 385px;
}

.card-live {
  width: 30px;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 3px;
  background: #ff3434;
  color: white;
  text-align: center;
  border-radius: 4px;
  font-size: 10px;
}
.card-live-viewcount {
  position: absolute;
  top: 10px;
  left: 29px;
  padding: 3px 10px 3px 13px;
  background: #0800003f;
  color: white;
  text-align: center;
  border-radius: 4px;
  font-size: 10px;
}

.card-live-totalview {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 3px 10px 3px 13px;
  background: #0800003f;
  color: white;
  text-align: center;
  border-radius: 4px;
  font-size: 10px;
}

.card-live-fault {
  width: 30px;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 3px;
  background: #2d2c2c;
  color: white;
  text-align: center;
  border-radius: 4px;
  font-size: 10px;
}

.card-stop {
  width: 48px;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 3px;
  background: #2d2c2c;
  color: rgb(147, 141, 141);
  text-align: center;
  border-radius: 4px;
}

.card-vod {
  width: 30px;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 3px;
  background: #e6f104;
  color: rgb(244, 6, 6);
  text-align: center;
  border-radius: 4px;
  font-size: 10px;
}

.card-title {
  font-weight: 700;
  font-size: 14px;
  padding: 0px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-desc {
  font-size: 12px;
  min-height: 32px;
}

.card-host {
  font-size: 14px;
  padding: 0px 9px;
  margin-top: 10px;
}

.card-tm {
  position: absolute;
  top: 41%;
  left: 50%;
  transform: translate(-50%);
  font-weight: 300;
  color: #ffffff;
  text-shadow: 1px 1px 1px gray;
  font-size: 28px;
}

.card-tm-vod {
  position: absolute;
  top: 41%;
  left: 50%;
  transform: translate(-50%);
  font-weight: 300;
  color: #ffffff;
  text-shadow: 1px 1px 1px grey;
  font-size: 28px;
}
