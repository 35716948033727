.thd-live-coupon-img-root {
  /* position: relative; */
  display: flex;
  flex-direction: column;
  width: 104px;
  align-items: flex-end;
}

.thd-live-coupon-img-container {
  width: 96px;
  height: 40px;
  padding: 10px 17px;
  background-image: url("../../../../public/images/coupon-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.thd-live-coupon-price {
  /* position: absolute;
  top: 39px;
  right: 10px; */
  margin-top: 5px;
  color: #ff376d;
  text-align: center;
}

.thd-live-coupon-login-container {
  width: 96px;
  height: 40px;
  padding: 6px 17px;
  background-image: url("../../../../public/images/btn-login.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-top: 10px;
}
