:root {
  --thd-green: #384e45;
  --thd-pink: #e6c6df;
}

.modal-share {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px;
  padding: 0;
}

.modal-top-border {
  display: flex;
  padding: 0;
  height: 6px;
}

.green-border {
  background-color: var(--thd-green);
  flex-basis: 35%;
}

.pink-border {
  background-color: var(--thd-pink);
  flex-basis: 65%;
}

.modal-content {
  padding: 20px;
}

.stack-row {
  width: 100%;
}

.item-small {
  width: 35px;
  height: 35px;
}

.modal-close {
  position: absolute !important;
  top: 10px;
  right: 5px;
}
