.chat-root {
  padding-bottom: 0px;
  z-index: 900;
}

.gradient-box {
  mask-image: linear-gradient(rgba(255, 255, 255, 0), black 13%, black 80%, black 100%);
  -webkit-mask-image: linear-gradient(rgba(255, 255, 255, 0), black 13%, black 80%, black 100%);
}

.chat-root-tmp {
  bottom: 90px;
  padding-bottom: 0px;
  z-index: 900;
}

.chat-root-announce {
  bottom: 120px;
  padding-bottom: 0px;
  z-index: 900;
}

.chat-root-announce-tmp {
  bottom: 140px;
  padding-bottom: 0px;
  z-index: 900;
}

.chat-root-input {
  bottom: 55px;
  padding-bottom: 0px;
  z-index: 900;
}

.chat-root-input-tmp {
  bottom: 75px;
  padding-bottom: 0px;
  z-index: 900;
}

.chat-root-input-announce {
  bottom: 105px;
  padding-bottom: 0px;
  z-index: 900;
}

.chat-root-input-announce-tmp {
  bottom: 125px;
  padding-bottom: 0px;
  z-index: 900;
}

.bg-dark {
  background: rgba(20, 20, 20, 0.2);
}

.chat-root-input-dark {
  position: absolute;
  bottom: 55px;
  left: 0px;
  padding-bottom: 0px;
  background: linear-gradient(to bottom, rgba(20, 20, 20, 0) 30%, rgba(20, 20, 20, 0.1) 10%, rgba(20, 20, 20, 0.2) 20%);
  z-index: 900;
}

.chat-admin {
  align-items: stretch;
  z-index: 900;
}

.chat-input {
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 900;
}

.chat-root-back {
  position: absolute;
  top: 100%;
  left: 5px;
  transform: translate(0%, -350px);
  z-index: 900;
}

.chat-list {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

.chat-list::-webkit-scrollbar {
  display: none;
}

.chat-list-admin {
  overflow-y: auto;
}

.live-announce-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-new-chat {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -183px);
  z-index: 901;
}

.btn-go-list {
  position: absolute;
  width: 100%;
  top: 10%;
  text-align: right;
  z-index: 810;
}

.mngrcamp-alert {
  position: absolute;
  width: 90%;
  top: 20%;
  left: 50%;
  transform: translate(-50%, 0%);
  opacity: 70%;
  z-index: 901;
}

.empty-68 {
  width: 68px;
}
