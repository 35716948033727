.thd-live-notice-wrapper {
  /* padding: 8px; */ /* 0104 허정은 변경 */
  padding: 0px 14px;
  height: 40.38px;
  transition: height 1s;
  width: 100%;
}

.thd-live-notice-wrapper.newQnA {
  height: 90px;
}

.thd-live-notice-root {
  /* position: absolute; */
  /* left: 14px; */
  /* right: 67px; */
  /* padding: 4px 5px; */
  /* box-sizing: border-box; */
  opacity: 0.9;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 6.8px;
  height: 100%;
  display: flex; /* 0104 허정은 변경 */
  align-items: center; /* 0104 허정은 변경 */
  border: none; /* 0104 허정은 변경 */
  transition: height 1s;
  z-index: 900;
  /* width: var(--footer-left-size); */
  width: 80%;
}

.thd-live-notice-item {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 10px;
}

.thd-live-notice-img {
  height: 15.3px;
  width: 11.42px;
}

.thd-live-notice-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.thd-live-notice-drawer-top {
  display: flex;
  align-items: center;
}

.thd-live-notice-drawer-top-img {
  width: 18px;
  height: 22px;
}
.thd-live-notice-drawer-top-text {
  font-family: "Noto Sans KR";
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  color: #111111;
}
.thd-live-notice-drawer-close {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.thd-live-notice-root.newQnA {
  border-radius: 10px;
}
/* 
.notice-slider {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 24px;
}

.notice-slider.newQnA {
  display: none;
}

.notice-silder-list {
  width: 100%;
  height: 500px;
  position: absolute;
  top: 0px;
  margin: 0;
  padding: 0 10px;
}

.notice-silder-list-animation {
  width: 100%;
  height: 500px;
  position: absolute;
  top: -24px;
  margin: 0;
  padding: 0 10px;
  transition: all 2s;
}

.notice-silder-list > div {
  display: flex;
}

.notice-silder-list-animation > div {
  display: flex;
}

.notice-silder-item {
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.notice-silder-item span {
  display: flex;
  align-items: center;
} */

.thd-newQnA-container {
  padding: 0px 5px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
}

.thd-live-notice-more {
  padding: 20px 0px;
  color: #777777;
}

.thd-live-notice-list {
  width: 100%;
  height: 52vh;
  overflow-y: auto;
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
}

.thd-live-notice-list::-webkit-scrollbar {
  display: none;
}
