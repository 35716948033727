.remain-item {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  z-index: 902;
}

.remain-item div {
  font-family: "Noto Sans KR";
}

.remain-live-mark img{
  width: 11vmin;
  height: 11vmin;
  /* font-family: "Noto Sans KR";
  font-weight: 900;
  font-size: 3vw;
  padding: 2px 10px;
  border-radius: 20px;
  background: rgb(216, 60, 65);
  background: linear-gradient(90deg, rgba(216, 60, 65, 1) 0%, rgba(212, 99, 51, 1) 100%); */
}

.remain-comming-soon {
  margin-top: 6px;
  font-family: "Noto Sans KR";
  font-weight: 900;
  font-size: 8vw;
  line-height: 1em;
}

.remain-broad-time {
  margin-top: 6px;
  font-weight: 400;
  font-size: 4vw;
  /* text-shadow: rgb(0 0 0 / 27%) 1px 1px; */
  line-height: 1em;
}

.remain-time {
  margin-top: 15px;
  box-sizing: border-box;
  background-color: #FF376D;
  border-radius: 27px;
  padding: 3px 4vmin;
  display: flex;
  align-items: center;
  font-size: 3vw;
  font-weight: 700;
}
