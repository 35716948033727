.thd-live-game-wrapper {
  padding: 8px 14px;
  height: 65px;
  transition: height 1s;
  width: 100%;
  margin-top: 6px;
}

.thd-live-game-layout {
  /* left: 14px; */
  /* background: #ff376db2; */

  /* background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)); */
  /* border-radius: 10px; */
  height: 100%;
  display: flex;
  align-items: center;
  /* width: var(--footer-left-size); */
  /* width: 280px; */
  width: 80%;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
}

.thd-live-game-root {
  width: 100%;
  opacity: 0.9;
  display: flex;
  transition: height 1s;
  z-index: 900;
}

.thd-live-game-img {
  height: 56px;
  width: 70px;
  margin: 0px 0px 0px 10px;
  animation: shake-animation 4.72s ease-in-out infinite;
  transform-origin: 50% 50%;
}

@keyframes shake-animation {
  0% {
    transform: translate(0, 0);
  }

  15% {
    transform: rotate(25deg);
  }

  30% {
    transform: rotate(0deg);
  }

  45% {
    transform: rotate(25deg);
  }

  60% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(25deg);
  }

  90% {
    transform: rotate(0);
  }

  100% {
    transform: rotage(0deg);
  }
}

.thd-live-game-text {
  /* text-overflow: ellipsis;
  overflow: hidden; */
  white-space: nowrap;
}

.thd-live-game-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;
  overflow: hidden;
}

.thd-live-game-draw-box {
  width: 36px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thd-live-game-stack-row {
  display: flex;
  align-items: center;
}
