.client-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  min-width: 100%;
  max-width: 100%;

  background-color: black;
  z-index: 800;
}
.video-cover {
  object-fit: cover;
}
.video-contain {
  object-fit: contain;
}
.client-video iframe,
.client-video object,
.client-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.client-video-admin {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  min-width: 100%;
  max-width: 100%;
  object-fit: contain;
  background-color: black;
  z-index: 800;
}
.client-video-admin iframe,
.client-video-admin object,
.client-video-admin embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.box-screen-alert {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 850;
}

.video-controls {
  right: 0;
  left: 0;
  position: absolute;
  bottom: 0;
  margin-right: 8px;
  transition: all 0.2s ease;
  /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)); */
  z-index: 801;
}

.video-controls.hide {
  opacity: 0;
  pointer-events: none;
}

.bottom--11 {
  bottom: -11px !important;
}
.padding-10 {
  padding: 10px;
}

.slider-no-thumb {
  display: none;
}

.video-progress {
  position: relative;
  height: 8.4px;
  margin-bottom: 10px;
  width: 100%;
}

progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 2px;
  width: 100%;
  height: 8.4px;
  pointer-events: none;
  position: absolute;
  top: 0;
}

progress::-webkit-progress-bar {
  background-color: #e5e0e0;
  border-radius: 2px;
}

progress::-webkit-progress-value {
  background: #e90909;
  border-radius: 2px;
}

progress::-moz-progress-bar {
  border: 1px solid #e90909;
  background: #e90909;
}

.seek {
  position: absolute;
  top: 0;
  width: 100%;
  cursor: pointer;
  margin: 0;
}

.seek:hover + .seek-tooltip {
  display: block;
}

.seek-tooltip {
  display: none;
  position: absolute;
  top: -50px;
  margin-left: -20px;
  font-size: 12px;
  padding: 3px;
  content: attr(data-title);
  font-weight: bold;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
}

.hidden {
  display: none;
}

@media (pointer: coarse) {
  .css-iwbskn-MuiSlider-root {
    padding: 0px 0;
  }
}
